import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "react-bootstrap";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({ 
      logoutParams: { 
        // returnTo: 'https://login.techccp.com/logout'
        returnTo: `https://login.techccp.com/v2/logout?client_id=BzzlZ5YRFgR5lf9zglzEGqkjmGLmjVwn&returnTo=${window.location.origin}`
      } 
    });
  };

  return (
    <Button onClick={handleLogout}>
      Log Out
    </Button>
  );
};