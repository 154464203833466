import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "react-bootstrap";
import { Loading } from "../Shared/Loading";

export default function Account() {
 
    const {user, isLoading} = useAuth0();

    return (
        <Container className="content-lr-padding">
            <h1 className="title">Account</h1>
            {isLoading ?
                <Loading />
                : 
                    <ul>
                        <li><span className="accent">Email:</span> {user!.email}</li>
                        <li><span className="accent">Name:</span> {`${user!.family_name} ${user!.given_name}`}</li>
                        <li><span className="accent">Phone Number:</span> {user!['https://www.crystalfunds.com/mobilePhone']}</li>
                    </ul>
            }
        </Container>
    )

}