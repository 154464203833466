import { Container } from "react-bootstrap";

export default function Home() {

    return (
        <Container className="content-centered content-lr-padding home-background">
          <div className="overlay">
            <h1 className="overlay-text">Welcome to My Alts Advisor</h1>
            <h3 className="overlay-text">Please <span className="accent">log in</span> to explore and build alts portfolios</h3>
          </div>
        </Container>
    );
}