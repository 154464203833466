import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Account from "./Pages/Account";
import { Auth0ProviderWithNavigate } from "./Auth/Auth0ProviderWithNavigate";
import { Dashboard } from "./Pages/Dashboard";
import { TopMenu } from "./Shared/TopMenu";
import { AuthenticationGuard } from "./Auth/AuthenticationGuard";


const App = () => {


  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <TopMenu />
        <div id="page-content">
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/dashboard' element={<AuthenticationGuard component={Dashboard}/>}/>
            <Route path='/account' element={<AuthenticationGuard component={Account} />}/>
          </Routes>
        </div>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  );
}

export default App;
