import { Container, Image, Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import crystalLogo from "../../logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "../Shared/Loading";

export const Dashboard = () => {
    const { isLoading } = useAuth0();
    return (
        <Container className="content-lr-margin">
            <h1 className="title">Dashboard</h1>
            {isLoading ?
                <Loading />
                : <>
                    <h4 style={{ 'fontWeight' : '700' }}>To build portfolios and invest in Alts please choose from one of our partners</h4>
                    <div className="content-centered spacer">
                        <Link to={`https://ruby.techccp.com/Account/SAML`}>
                            <Button variant="light" style={{ 'cursor': 'pointer', 'height': '15rem', 'width': '15rem', 'boxShadow': '0 4px 15px rgba(0, 0, 0, 0.5)' }}>
                                <Image
                                    aria-hidden
                                    src={crystalLogo}
                                    alt="Crystal Capital Partners Logo"
                                    rounded
                                />
                            </Button>
                        </Link>
                    </div>
                </>
            }
        </Container>
    )
}