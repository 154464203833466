import { Container, Nav, Navbar, Image } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from '../Buttons/LoginButton';
import { LogoutButton } from '../Buttons/LogoutButton';
import logo from '../../myAltsLogo.svg'

export const TopMenu = () => {

    const {isAuthenticated, isLoading} = useAuth0();

    const isActive = (link: string) => {
        return window.location.pathname === link;
    }

    return (
        <Navbar expand="lg" className='navbar-style bg-body-primary' >
            <Container>
                <Navbar.Brand className='navbar-text' href={isAuthenticated ? "/dashboard" : "/"}>
                <strong><Image
                            style={{ 'height' : '25px', 'marginLeft': '10px','marginRight': '10px'}}
                            aria-hidden
                            src={logo}
                            alt="Crystal Capital Partners Logo"
                            rounded
                        /> My Alts Advisors</strong>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                {isLoading ?
                    <></>
                    : <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {isAuthenticated &&
                                <>
                                    <Nav.Link active={isActive("/dashboard")} href="/dashboard">Dashboard</Nav.Link>
                                    <Nav.Link active={isActive("/account")} href="/account">Account</Nav.Link>
                                </>
                            }
                        </Nav>
                        {!isAuthenticated ?
                            <LoginButton />
                            : <LogoutButton />
                        }
                    </Navbar.Collapse>
                }
            </Container>
        </Navbar>
    )
}