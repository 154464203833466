import { Auth0Provider } from '@auth0/auth0-react';

export const Auth0ProviderWithNavigate = ({ children }) => {

    const domain = process.env.REACT_APP_DOMAIN;
    const clientId = process.env.REACT_APP_ClientId;
    const redirectUri = process.env.REACT_APP_Redirect;

    const onRedirectCallback = appState => {
        window.location.replace(appState?.returnTo);
    };

    if (!(domain && clientId && redirectUri)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};
